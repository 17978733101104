<template>
    <div class="borad-container">
        <div class="gallery-items">
            <div v-for="board in boards" :key="board._id" @click="$emit('click', board)" class="gallery-item">
                <div class="gallery-item__inner">
                    <div class="gallery-item__img" :style="{ backgroundImage: `url('${board.thumb}')`}"></div>
                    <div class="gallery-item__con">
                        <h3 class="gallery-item__tit">{{ board.subject }}</h3>
                        <div class="gallery-item__info">
                            <strong>{{ board.meta.authorName }}</strong><span>{{ board.meta.workType }}</span>
                        </div>
                        <div class="gallery-item__txt">{{ board.summary }}</div>
                        <div v-if="board.meta.buyEnabled" class="gallery-item__btn-wrap">
                            <v-btn color="primary" class="gallery-item__btn">
                                <span>작품보기</span><i class="icon icon-arrow"></i>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="border-slide">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="board in boards" :key="board._id" class="swiper-slide" @click="$emit('click', board)">
                    <div class="gallery-item">
                        <div class="gallery-item__inner">
                            <div class="gallery-item__img" :style="{ backgroundImage: `url('${board.thumb}')`}"></div>
                            <div class="gallery-item__con">
                                <h3 class="gallery-item__tit">{{ board.subject }}</h3>
                                <div class="gallery-item__info">
                                    <strong>{{ board.meta.authorName }}</strong><span>{{ board.meta.workType }}</span>
                                </div>
                                <div class="gallery-item__txt">{{ board.summary }}</div>
                                <div v-if="board.meta.buyEnabled" class="gallery-item__btn-wrap">
                                    <v-btn color="primary" class="gallery-item__btn">
                                        <span>작품보기</span><i class="icon icon-arrow"></i>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="prev"></div>
        <div class="next"></div>
    </div> -->
</template>

<script>
export default {
    props: {
        boards: Array
    },
    mounted(){
        this.$nextTick(() => {
            this.render();
        });
    },
    methods: {
        render() {
            var viewCount = 4;
            var container = this.$el.getElementsByClassName("swiper-container")[0];
            new window.Swiper(container, {
                centeredSlides: false,
                slidesPerView: viewCount,
                spaceBetween: 30,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: viewCount <= this.boards.length,
                pagination: {
                    el: '.pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.border-slide .next',
                    prevEl: '.border-slide .prev'
                },
                breakpoints: {
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 4,
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 4,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 14,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                }
            });
        },
    },
    watch: {
        boards(){
            this.$nextTick(() => {
                this.render();
            });
        }
    }
}
</script>